import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// import { useMutation } from "@tanstack/react-query";
import { ConfigProvider, DatePicker } from "antd";
import locale from "antd/locale/es_ES";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { isEmpty } from "lodash";

import timeBlockService from "~/utils/api/v1/timeblockService";
// import { createBlockedNurseItem } from "~/utils/api/v2/nurse";
// import { BlockedNurseItemPayload } from "~/utils/interfaces/Nurse";
import { TimeBlock } from "~/utils/interfaces/Timeblock";

dayjs.locale("es");

type AddBlockedTimeblockProps = {
  id: string;
  timeblocks: TimeBlock[];
  onCancel: () => void;
  refetchNurseTimeblocks: () => void;
};

// const { RangePicker } = DatePicker;

const AddBlockedTimeblock = ({
  id: nurseId,
  timeblocks,
  onCancel,
  refetchNurseTimeblocks,
}: AddBlockedTimeblockProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<object>({});
  const [selectedBeginHour, setSelectedBeginHour] = useState<string>(timeblocks[0].begin_hour);
  const [selectedEndHour, setSelectedEndHour] = useState<string>(timeblocks[0].end_hour);
  const [date, setDate] = useState<dayjs.Dayjs>(dayjs());
  // TODO: Implement this in next PR
  // const [beginDate, setBeginDate] = useState<dayjs.Dayjs>(dayjs());
  // const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs());
  // const [itemsIds, setItemsIds] = useState<string[]>([]);

  // const { mutate: createBlockedNurseItemMutation } = useMutation({
  //   mutationFn: async (data: BlockedNurseItemPayload) => {
  //     createBlockedNurseItem(data);
  //   },
  //   onSuccess: () => {
  //     refetchNurseTimeblocks();
  //   },
  //   onError: (error) => {
  //     setError({ error: error.message });
  //   },
  // });

  const selectedTimeBlocksIds = (): Array<string> => {
    const selectedDay = date.locale("en").format("dddd").toLowerCase();
    return timeblocks.reduce((reducer: string[], timeblock: TimeBlock) => {
      if (timeblock.day === selectedDay) {
        if (timeblock.begin_hour >= selectedBeginHour && timeblock.end_hour <= selectedEndHour) {
          reducer.push(timeblock.id);
        }
      }
      return reducer;
    }, []);
  };

  const handleAcceptButton = async (): Promise<void> => {
    setLoading(true);
    setError({});
    try {
      const selectedIds = selectedTimeBlocksIds();
      await Promise.all(
        selectedIds.map(async (id: string) => {
          return timeBlockService.blockTimeBlock(id, {
            date: date.format("YYYY-MM-DD"),
            nurse_id: nurseId,
          });
        }),
      );

      await refetchNurseTimeblocks();
    } catch (err) {
      setError({ error: `${err}` });
    }
    setLoading(false);
  };

  // TODO: Implement this in next PR

  // const handleAcceptItemsButton = async (): Promise<void> => {
  //   setLoading(true);
  //   setError({});
  //   createBlockedNurseItemMutation({
  //     nurseId,
  //     itemsIds,
  //     beginDate: beginDate.toISOString(),
  //     endDate: endDate.toISOString(),
  //   });
  //   setLoading(false);
  // };

  return (
    <div className="flex flex-col mt-4">
      <div className="flex flex-col">
        <h4>Bloquear disponibilidad</h4>
        <div className="flex flex-row items-center justify-start gap-4">
          <ConfigProvider locale={locale}>
            <DatePicker
              onChange={(date) => {
                setDate(dayjs(date));
              }}
              className="w-1/3"
              placeholder="Fecha inicio"
              format="DD/MM/YYYY"
              minDate={dayjs()}
              size="large"
            />
          </ConfigProvider>
          <FormControl>
            <InputLabel>Inicio</InputLabel>
            <Select
              value={selectedBeginHour}
              onChange={(e) => {
                setSelectedBeginHour(e.target.value as string);
              }}
            >
              {timeblocks.map((timeblock: TimeBlock) => {
                // assume all days have the same possible timeblocks available
                if (timeblock.day === "monday") {
                  return (
                    <MenuItem
                      key={timeblock.begin_hour}
                      value={timeblock.begin_hour}
                    >
                      {timeblock.begin_hour}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>Término</InputLabel>
            <Select
              value={selectedEndHour}
              onChange={(e) => {
                setSelectedEndHour(e.target.value as string);
              }}
            >
              {timeblocks.map((timeblock: TimeBlock) => {
                // assume all days have the same possible timeblocks available
                if (timeblock.day === "monday") {
                  return (
                    <MenuItem
                      key={timeblock.end_hour}
                      value={timeblock.end_hour}
                    >
                      {timeblock.end_hour}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
        </div>
        <div className="flex flex-row items-center justify-start gap-4 my-4">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleAcceptButton}
            disabled={loading}
          >
            Agregar
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={onCancel}
            disabled={loading}
          >
            Cancelar
          </Button>
        </div>
      </div>
      {/* TODO: Implement this in next PR */}
      {/* <div className="flex flex-col">
        <h4>Bloquear Items</h4>
        <div className="flex flex-row items-center justify-start gap-4">
          <ConfigProvider locale={locale}>
            <RangePicker
              size="large"
              showTime={{ format: "HH:mm" }}
              format="DD/MM/YYYY HH:mm"
              onChange={(value) => {
                if (value) {
                  setBeginDate(dayjs(value[0]));
                  setEndDate(dayjs(value[1]));
                }
              }}
              value={[beginDate, endDate]}
            />
          </ConfigProvider>
        </div>
        <div className="flex flex-row items-center justify-start gap-4 my-4">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleAcceptItemsButton}
            disabled={loading}
          >
            Agregar
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={onCancel}
            disabled={loading}
          >
            Cancelar
          </Button>
        </div>
      </div> */}
      {!isEmpty(error) && (
        <div className="text-red-500 flex justify-center">{JSON.stringify(error)}</div>
      )}
    </div>
  );
};

export default AddBlockedTimeblock;
